<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { reactive, onMounted, onUnmounted, ref } from 'vue';

import Time from '@/assets/time-hover.png';
import Read from '@/assets/ic_read_normal.png';

import Navbar from '@/components/NavbarPhone.vue';
import RouterModal from '@/components/RouterModal.vue';
import FormModal from '@/components/FormModal.vue';
import CookieModal from '@/components/CookieModal.vue';
import Footer from '@/components/FooterPhone.vue';
import Play from '@/assets/phone-play.png';
import Stop from '@/assets/phone-stop.png';
import Mb from '@/assets/mb.png';

import { getNewsList } from '@/api';

import { getBrowserEngine, isWeChatBrowser, observeFun } from '@/utils';

import dayjs from 'dayjs';

const { locale } = useI18n();
const fadeClass = ref('');
let observers: any = [];
const sections = ref(['section-1']);
const activeSection = ref<string[]>([]);
const showNavBar = ref(true);
const showMenu = ref(false);
const showForm = ref(false);
const isPlay = ref<any>(false);
const videoPlayer = ref<any>(null);
const pageObj = reactive({ page: 1, page_size: 100000 });
const news: any = ref([]);
const bannerData: any = ref([]);
const displayedItems: any = ref([]);
const itemsToShowInitially = 5;
const itemsToLoadMore = 5;
const canLoadMore = ref(false);
const upMore = ref(false);

const closeHandle = () => {
  showMenu.value = false;
};

// 定义一个函数获取当前设备的宽度
const getWinSize = () => {
  let width = document.documentElement.clientWidth;
  // 假设设计稿宽度为750px
  // 假设已知根元素我们设置为100px（这里设置100方便后续我们好计算）
  // 动态设置根元素html的fontSize
  document.documentElement.style.fontSize = 100 * (width / 430) + 'px';
};

const handleScroll = () => {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const viewportHeight = (document as any)
    .getElementById('banner')
    .getBoundingClientRect().height;
  if (scrollTop > viewportHeight / 1.8) {
    // Scroll down
    showNavBar.value = false;
  } else {
    // Scroll up
    showNavBar.value = true;
  }
};

/**
 * @description 获取新闻列表
 */
const getNews = async () => {
  try {
    let res: any = await getNewsList(pageObj);
    if (res.code === 200) {
      setTimeout(() => {
        fadeClass.value = 'fade-up';
      });
      observeFun(sections, activeSection, observers, 0.1, 'section');
      bannerData.value = res.data.list.filter(
        (item: any) => item.source === '首屏'
      );
      const arr = res.data.list.filter((item: any) => item.source !== '首屏');
      news.value = arr;
      displayedItems.value = arr.slice(0, itemsToShowInitially);
      canLoadMore.value = displayedItems.value.length < arr.length;
    }
  } catch (error) {
    console.log(error);
  }
};
/**
 * @description 加载更多
 */
const loadMore = () => {
  // 收起
  if (upMore.value) {
    displayedItems.value = [];
    const nextItems = news.value.slice(0, itemsToShowInitially);
    displayedItems.value = displayedItems.value.concat(nextItems);
    upMore.value = false;
  } else {
    // 加载更多
    const currentLength = displayedItems.value.length;
    const nextItems = news.value.slice(
      currentLength,
      currentLength + itemsToLoadMore
    );
    displayedItems.value = displayedItems.value.concat(nextItems);
    if (displayedItems.value.length >= news.value.length) {
      upMore.value = true;
    }
  }
};
const bannerJump = () => {
  if (bannerData.value[0].url !== '') {
    // 创建a元素
    const link = document.createElement('a');
    // 设置链接的目标URL
    link.href = bannerData.value[0].url;

    // 设置target属性为_blank
    link.target = '_blank';

    // 将a元素添加到文档中（这一步可以不做）
    document.body.appendChild(link);

    // 模拟点击该链接
    link.click();

    // 如果不想在文档中保留该链接，可以移除
    document.body.removeChild(link);
  }
};
const jumpHandle = (item: any) => {
  // 创建a元素
  const link = document.createElement('a');
  console.log(item.url, 'item.url');
  // 设置链接的目标URL
  link.href = item.url;

  // 设置target属性为_blank
  link.target = '_blank';

  // 将a元素添加到文档中（这一步可以不做）
  document.body.appendChild(link);

  // 模拟点击该链接
  link.click();

  // 如果不想在文档中保留该链接，可以移除
  document.body.removeChild(link);
};

const playVideo = () => {
  if (videoPlayer.value.paused) {
    videoPlayer.value
      .play()
      .then(() => {
        isPlay.value = true;
      })
      .catch((error: any) => {
        console.error('视频播放失败:', error);
      });
  } else {
    videoPlayer.value.pause();
    isPlay.value = false;
  }
};

onMounted(() => {
  getWinSize();
  //当页面窗口视图改变的时候再次执行
  window.onresize = getWinSize;
  window.addEventListener('scroll', handleScroll);

  getNews();
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>
<template>
  <div class="news" :class="{ 'news-zh': locale === 'zh' }">
    <Navbar v-if="showNavBar" @change="showMenu = true" @close="closeHandle" />
    <div class="news-banner" id="banner" @click="bannerJump">
      <img class="mb" :src="Mb" alt="" />

      <video
        ref="videoPlayer"
        v-if="
          getBrowserEngine() === 'WebKit/Blink' &&
          bannerData.length &&
          bannerData[0].video_h5_url !== ''
        "
        :autoplay="isWeChatBrowser() ? false : true"
        loop
        playsinline
        muted
        :poster="bannerData[0].photo_h5_url"
      >
        <source :src="bannerData[0].video_h5_url" type="video/mp4" />
      </video>
      <img
        v-if="
          getBrowserEngine() === 'WebKit/Blink' &&
          bannerData.length &&
          bannerData[0].video_h5_url === ''
        "
        :src="bannerData[0].photo_h5_url"
        alt=""
      />
      <img
        v-if="
          getBrowserEngine() !== 'WebKit/Blink' &&
          bannerData.length &&
          bannerData[0].video_h5_url === ''
        "
        :src="bannerData[0].photo_h5_url"
        alt=""
      />
      <div class="text" :class="fadeClass">
        <img
          v-if="isWeChatBrowser()"
          :src="isPlay ? Stop : Play"
          alt=""
          @click="playVideo"
        />
        <div @click="bannerJump">
          <div v-if="bannerData.length" class="text-main">
            <span>{{
              locale === 'en'
                ? bannerData[0].eng_content
                : bannerData[0].content
            }}</span>
            <!-- <img class="read" :src="Read" alt="" /> -->
          </div>
          <div v-if="bannerData.length">
            <div class="tags">
              {{ locale === 'en' ? bannerData[0].eng_title : bannerData[0].title }}
            </div>
            <div class="time">
              {{
                locale === 'en'
                  ? dayjs(bannerData[0].publish_time).format('MMMM D, YYYY')
                  : dayjs(bannerData[0].publish_time).format('YYYY年MM月DD日')
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="news-main">
      <div style="width: 100%; height: 1px" id="section-1"></div>
      <div
        class="news-list"
        :class="{ fade: activeSection.includes('section-1') }"
      >
        <div
          v-for="(item, index) in displayedItems"
          :key="index"
          class="list-item"
          @click="jumpHandle(item)"
        >
          <div class="list-item-top">
            <img :src="item.photo_h5_url" alt="" />
          </div>
          <div class="item-title">
            <img :src="Time" alt="" />
            {{ locale === 'en' ? item.eng_title : item.title }}
          </div>
          <div class="item-decs">
            {{ locale === 'en' ? item.eng_content : item.content }} Year's
            Biggest Angel Round Announced Year's Biggest Angel Round Announced
            Year's Biggest Angel Round Announced
          </div>
          <div class="item-time">
            {{
              locale === 'en'
                ? item.publish_time
                  ? dayjs(item.publish_time).format('MMMM D, YYYY')
                  : ''
                : item.publish_time
                ? dayjs(item.publish_time).format('YYYY年MM月DD日')
                : ''
            }}
          </div>
        </div>
      </div>
      <div v-if="canLoadMore" class="more" @click="loadMore">
        <span v-if="!upMore">{{ locale === 'en' ? 'More' : '查看更多' }}</span>
        <img
          class="more"
          :class="`${upMore ? 'upMore' : ''}`"
          :src="Read"
          alt=""
        />
      </div>
    </div>

    <Footer @change="showForm = true" />
    <RouterModal :class="showMenu ? 'active' : ''" @close="closeHandle" />
    <FormModal
      :class="showForm ? 'active-form' : ''"
      @close="showForm = false"
    />
    <CookieModal />
  </div>
</template>

<style scoped lang="scss">
.news {
  width: 100%;
  &-banner {
    position: relative;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow: hidden;
    .mb {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .text {
      width: 3.8rem;
      position: absolute;
      left: 0.24rem;
      bottom: 0.65rem;
      opacity: 0;
      transform: translateY(80%);
      transition: opacity 1.5s, transform 1s;
      img {
        display: flex;
        margin-bottom: 0.1rem;
        width: 0.4rem;
        height: 0.4rem;
      }
      > div {
        font-family: Verdana;
        font-size: 0.28rem;
        color: #ffffff;
        font-weight: 400;

        .text-main {
          display: flex;
          display: -webkit-box; /* 必须 */
          -webkit-box-orient: vertical; /* 必须 */
          -webkit-line-clamp: 3; /* 显示3行 */
          overflow: hidden; /* 隐藏溢出内容 */
          text-overflow: ellipsis; /* 使用省略号表示溢出 */
          span {
            display: block;
            width: 3rem;
          }
        }
        > div {
          position: relative;
          margin-bottom: 0.18rem;
          > div {
            color: rgb(214, 219, 232, 0.8);
            font-family: Arial;
            font-size: 0.12rem;
            font-style: normal;
            font-weight: 400;
          }
          .read {
            // position: absolute;
            right: 0;
            bottom: 0;
            width: 0.22rem;
            height: 0.22rem;
          }
        }
        > div:last-child {
          display: flex;
          align-items: center;
          .time {
            display: block;
            height: 0.25rem;
            line-height: 0.22rem;
            border-radius: 0.5rem;
            border: 0.01rem solid rgb(180, 188, 208, 0.5);
            padding: 0 0.13rem;
            background: rgba(23, 26, 32, 0.5);
            box-sizing: border-box;
          }
          .tags {
            padding: 0 0.18rem;
            height: 0.25rem;
            line-height: 0.22rem;
            box-sizing: border-box;
            display: block;
            max-width: 2rem;
            margin-right: 0.1rem;
            border-radius: 0.55rem;
            display: inline-block; /* 必须 */
            white-space: nowrap; /* 强制在一行显示 */
            overflow: hidden;
            text-overflow: ellipsis;
            background: rgba(23, 26, 32, 0.5);
            border: 0.01rem solid rgb(131, 167, 255, 0.5);
          }
        }
      }
    }
    .fade-up {
      opacity: 1 !important;
      transform: translateY(0) !important;
    }
  }
  &-main {
    max-width: 100%;
    padding: 0 0.24rem;
    box-sizing: border-box;
    margin: 0 auto;
    .news-list {
      width: 100%;
      opacity: 0;
      transform: translateY(100%);
      transition: opacity 1s, transform 1s;
      margin-top: 0.22rem;
      .list-item {
        position: relative;
        width: 100%;
        height: 3.6rem;
        border-radius: 0.1rem;
        border: 0.01rem solid rgba(70, 87, 121, 0.8);
        background: rgba(23, 26, 32, 0.6);
        transition: background 0.2s ease-in-out;
        margin-bottom: 0.22rem;
        &-top {
          display: flex;
          margin-bottom: 0.12rem;
          overflow: hidden;
          width: 100%;
          border-radius: 0.1rem 0.1rem 0px 0px;
          img {
            width: 100%;
            height: 2.3rem;
            border-radius: 0.1rem 0.1rem 0px 0px;
            scale: 1;
            transition: scale 0.2s ease-in-out;
          }
        }
        .item-title {
          display: flex;
          align-items: center;
          color: #a1ebff;
          font-family: Arial;
          font-size: 0.12rem;
          font-style: normal;
          font-weight: 400;
          line-height: 0.2rem;
          margin-bottom: 0.12rem;
          display: flex;
          display: -webkit-box; /* 必须 */
          -webkit-box-orient: vertical; /* 必须 */
          -webkit-line-clamp: 1; /* 显示3行 */
          overflow: hidden; /* 隐藏溢出内容 */
          text-overflow: ellipsis; /* 使用省略号表示溢出 */
          padding-right: 0.2rem;
          img {
            position: relative;
            top: 0.01rem;
            width: 0.13rem;
            height: 0.13rem;
            margin-left: 0.24rem;
            margin-right: 0.06rem;
            border-radius: 0;
          }
        }
        .item-decs {
          width: 100%;
          color: #b4bcd0;
          font-family: MyRegular;
          font-size: 0.15rem;
          font-style: normal;
          font-weight: 300;
          line-height: 0.2rem;
          padding: 0 0.24rem;
          box-sizing: border-box;
          display: flex;
          display: -webkit-box; /* 必须 */
          -webkit-box-orient: vertical; /* 必须 */
          -webkit-line-clamp: 2; /* 显示3行 */
          overflow: hidden; /* 隐藏溢出内容 */
          text-overflow: ellipsis; /* 使用省略号表示溢出 */
          transition: color 0.2s ease-in-out;
        }
        .item-time {
          position: absolute;
          right: 0.2rem;
          bottom: 0.14rem;
          color: #d6dbe8;
          font-family: MyRegular;
          font-size: 0.12rem;
          font-style: normal;
          font-weight: 500;
          opacity: 0.4;
          transition: opacity 0.2s ease-in-out;
        }
      }
    }
    .fade {
      opacity: 1 !important;
      transform: translateY(0) !important;
    }
    .more {
      cursor: pointer;
      width: 1.2rem;
      z-index: 9999;
      z-index: 1;
      font-family: Verdana;
      font-size: 0.2rem;
      float: right;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      justify-self: center;
      color: #fff;
      font-family: Helvetica;
      font-weight: 400;
      text-decoration: none;
      box-sizing: border-box;
      -webkit-tap-highlight-color: transparent !important;
      tap-highlight-color: transparent !important;
      outline: none;
      &:focus {
        outline: none;
        background: transparent;
        -webkit-tap-highlight-color: transparent;
      }
      &:active {
        background: transparent;
        outline: none;
        -webkit-tap-highlight-color: transparent;
      }
      img {
        width: 0.24rem;
        height: 0.24rem;
        margin-left: 0.1rem;
        margin-right: 0.1rem;
        transform: rotate(0deg);
      }
      .upMore {
        transform: rotate(-90deg);
      }
    }
  }
}
.news-zh {
  .news-banner .text {
    > div {
      font-family: MyNotoRegular;
      font-size: 0.26rem;
      font-weight: 400;
      span {
        font-family: MyNotoRegular;
        font-size: 0.26rem;
        font-weight: 400;
      }
      .text-main {
        .read {
          right: 0px;
        }
      }
      > div {
        .tags {
          font-family: MyNoto;
        }
        .time {
          font-family: MyNoto;
        }
      }
    }
  }
  .news-main {
    .more {
      font-size: 0.18rem;
      font-family: MyNoto;
    }
    .news-list {
      .list-item {
        .item-title {
          font-family: MyNoto;
        }
        .item-decs {
          font-size: 0.14rem;
          font-family: MyNoto;
        }
        .item-time {
          font-family: MyNoto;
        }
      }
      .more {
        font-family: MyNoto;
      }
    }
  }
}
</style>
